const infirmaryModuleStore = {
  namespaced: true,
  state: {
    moduleName: 'Infermeria',
    allergies: [],
    vaccines: [],
  },
  mutations: {
    // Mutations
    setAllergies(state, allergies) {
      state.allergies = allergies.map((m) => {
        return m
      })
    },
    setVaccines(state, vaccines) {
      state.vaccines = vaccines.map((v) => {
        return v
      })
    },
  },
  actions: {
    // Actions
    allergies({ commit }, allergies) {
      commit('setAllergies', allergies)
    },
    vaccines({ commit }, vaccines) {
      commit('setVaccines', vaccines)
    },
  },
  getters: {
    // Getters
  }
};

export default infirmaryModuleStore;