<template>
  <div :class="cssClasses">
    <div>
      <div class="vx-row mb-5 align-items-end">
        <div class="vx-col sm:w-1/2 w-full" v-if="showSearch">
          <label class="text-sm opacity-75" v-if="showPlaceholder">
            {{ placeholderText }}
          </label>
          <vs-input type="text" icon-no-border :placeholder="placeholderSearchText" v-model="searchQuery"
            @keyup="searchTimeOut()" class="w-full input-rounded-full" icon="icon-search" icon-pack="feather" />
        </div>
        <div :class="filterCssClasses">
          <slot name="filter"></slot>
        </div>
        <div class="vx-col add-button">
          <slot name="add"></slot>
        </div>
      </div>
      <div v-if="showSelectAll">
        <slot name="select-all"></slot>
      </div>
    </div>

    <vs-table :data="requestData.data" :tableHeaders="visibleHeaders" :sst="true" @sort="handleSort"
      noDataText="Nuk ka të dhëna!">
      <template #thead>
        <vs-th v-if="showSelectAll">
        </vs-th>
        <vs-th v-for="header in visibleHeaders" :sort-key="header.disableSort ? null : header.key" :key="header.key">{{
    header.label }}</vs-th>
        <vs-th v-if="showActions">Aksionet</vs-th>
      </template>

      <template #default="{ data }">
        <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data" :class="{ clickable: rowClickable }">
          <vs-td v-if="showSelectAll" style="padding-right: 0 !important;">
            <slot name="checkbox" v-bind:data="tr"></slot>
          </vs-td>
          <td v-for="header in visibleHeaders" :key="header.key" @click="rowClick(data[indextr])">
            <template v-if="header.type === 'date'">
              <p>{{ $smnDate(getValue(data[indextr], header.key)) }}</p>
            </template>
            <template v-else-if="header.type === 'day_of_week'">
              <p>{{ $smnDayOfWeek(getValue(data[indextr], header.key)) }}</p>
            </template>
            <template v-else-if="header.type === 'unit_time'">
              <p>{{ $smnClassTime(getValue(data[indextr], header.key)) }}</p>
            </template>
            <template v-else-if="header.type === 'time'">
              <p>{{ $smnTime(getValue(data[indextr], header.key)) }}</p>
            </template>
            <template v-else-if="header.type === 'class_number'">
              <p>{{ $smnClassNumberFilter(getValue(data[indextr], header.key)) }}</p>
            </template>
            <template v-else-if="header.key === 'user.active'">
              <p>{{ $smnActiveStatusFilter(getValue(data[indextr], header.key)) }}</p>
            </template>
            <template v-else-if="header.type === 'contract_deadline'">
              <p :class="getContractDeadlineClass(data[indextr].contract_deadline)">
                {{ $smnDate(getValue(data[indextr], header.key)) }}
              </p>
            </template>
            <template v-else-if="header.key === 'status'">
              <span :class="getStatusBadgeClass(data[indextr]._status)">
                {{ getValue(data[indextr], header.key) }}
              </span>
            </template>
            <template v-else-if="header.type === 'contract'">
              <p :class="getContractStatusClass(data[indextr].is_sign_contract)" class="font-medium">
                {{ $contractStatus(getValue(data[indextr], header.key)) }}
              </p>
            </template>
            <template v-else-if="header.type === 'description'">
              <p>
                {{ $truncate(getValue(data[indextr], header.key), 50) }}
              </p>
            </template>
            <template v-else>
              <p>{{ getValue(data[indextr], header.key) }}</p>
            </template>
          </td>
          <vs-td class="smn-table__actions" v-if="showActions">
            <slot name="actions" :data="data[indextr]"></slot>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="flex justify-end mt-3" v-if="showColumnsSettings">
      <feather-icon class="cursor-pointer" color="primary" iconPack="feather" icon="icon-settings"
        @click.stop="openColumnsPopup = true" />
    </div>

    <div class="flex flex-col md:flex-row w-full md:justify-between items-center mt-5">
      <slot name="see-notused-professors"></slot>
      <slot name="see-all"></slot>
      <vs-pagination v-if="requestData?.data && requestData.data.length > 0" class="mt-4" :total="requestData.last_page"
        v-model="page" @click="changePage" :max="maxPaginationLength"></vs-pagination>
    </div>

    <vs-popup title="Ndrysho shfaqjen e kolonave" v-model:active="openColumnsPopup">
      <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)" class="mb-2">
        <span>Shfaq/Largo kolonat:</span>
      </div>
      <div class="py-1" v-for="(header, index) in tableHeaders" :key="index">
        <vs-checkbox v-model="header.visible" :disabled="header.visible && !canToggleHeaderVisibility">
          {{ header.label }}
        </vs-checkbox>
      </div>
      <div class="flex justify-end">
        <vs-button class="mt-2" @click="saveColumnVisibility()">Ruaj</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: 'smn-table',
  props: {
    tableHeaders: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    relations: {
      type: String,
      default: ''
    },
    requestData: {
      type: Object,
      required: true,
      default: function () {
        return {
          current_page: 1,
          data: [],
          from: 1,
          last_page: 1,
          per_page: 1,
          to: 1,
          total: 1,
        }
      }
    },
    rowClickable: {
      type: Boolean,
      default: false
    },
    rowRoute: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showSelectAll: {
      type: Boolean,
      default: false
    },
    showColumnsSettings: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: "Kërko"
    },
    showPlaceholder: {
      type: Boolean,
      default: false
    },
    placeholderSearchText: {
      type: String,
      default: "Kërko"
    },
    cssClasses: {
      type: String,
      default: "smn-table"
    },
    filterCssClasses: {
      type: String,
      default: "vx-col md:w-1/4 sm:w-1/2 w-full"
    }
  },
  data() {
    return {
      searchQuery: '',
      keys: [this.tableHeaders.map((h) => h.key)],
      selected: [],
      page: 1,
      timeout: null,
      maxPaginationLength: 7,
      openColumnsPopup: false
    }
  },
  computed: {
    visibleHeaders() {
      const headers = this.tableHeaders.filter(header => header.visible);

      if (headers.length > 0)
        return headers

      return this.tableHeaders
    },
    canToggleHeaderVisibility() {
      return this.visibleHeaders.length > 1;
    },
  },
  watch: {
    $route(to, from) {
      // Reset the searchQuery when the route changes
      this.searchQuery = '';
    },
  },
  mounted() {
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);

    if(this.showColumnsSettings)
      this.loadColumnVisibility();
    else
      localStorage.removeItem(this.$route.name ? `columnVisibility_${this.$route.name}` : 'columnVisibility')

    this.handleSearch();
  },
  methods: {
    getStatusBadgeClass(status) {
      if (status == 'unpermitted') {
        return 'badge badge-yellow';
      } else if (status == 'permitted') {
        return 'badge badge-green';
      } else {
        return 'badge badge-red';
      }
    },
    getContractStatusClass(status) {
      if (status == 0) {
        return 'text-warning';
      } else if (status == 1) {
        return 'text-danger';
      }
      else if (status == 2) {
        return 'text-success';
      } else {
        return '';
      }
    },
    getContractDeadlineClass(deadline) {
      if (deadline) {
        const parsedDeadlineDate = new Date(deadline);

        const todayDate = new Date()

        const date1ms = parsedDeadlineDate.getTime();
        const date2ms = todayDate.getTime();

        if (date1ms < date2ms) {
          return 'text-danger font-medium';
        }
      }

      return '';
    },
    handleSearch() {
      let query = this.buildQuery();

      this.$emit('update', query)
    },
    buildQuery() {
      let relations = (this.relations) ? `&relation=${this.relations}` : '';
      let search = (this.searchQuery) ? `&search=${this.searchQuery}` : '';
      let fields = (this.keys) ? `&fields=${this.keys}` : '';
      let sort = (this.sort) ? `&sortField=${this.sort.key}&sortBy=${this.sort.direction}` : '';

      let params = `page=${this.page}&per_page=10${search}${relations}${fields}${sort}`

      return params
    },
    handleSort(key, direction) {
      if (key != null && key != undefined) {
        this.sort = {
          key,
          direction
        }
        this.handleSearch()
      }
    },
    changePage(page) {
      this.handleSearch()
    },
    getValue(data, key) {
      const keys = key.split('.');

      if (keys.includes('is_sign_contract')) {
        return this.getNested(data, ...keys) || 0;
      }

      if (keys.includes('active')) {
        return this.getNested(data, ...keys) || 0;
      }
      return this.getNested(data, ...keys) || undefined;
    },
    getNested(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj)
    },
    searchTimeOut() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.page = 1
        this.requestData.last_page = 1
        this.handleSearch()
      }, 1000);
    },
    rowClick(item) {
      if (this.rowClickable)
        this.$router.push({ name: this.rowRoute, params: { id: item.id } })

      return;
    },
    updateScreenWidth() {
      if (window.innerWidth <= 438 && window.innerWidth > 361)
        return this.maxPaginationLength = 5;
      else if (window.innerWidth <= 361)
        return this.maxPaginationLength = 1;

      return this.maxPaginationLength = 7
    },
    saveColumnVisibility() {
      this.openColumnsPopup = false;
      localStorage.setItem(this.$route.name ? `columnVisibility_${this.$route.name}` : 'columnVisibility', JSON.stringify(this.tableHeaders));
    },
    loadColumnVisibility() {
      const visibility = localStorage.getItem(this.$route.name ? `columnVisibility_${this.$route.name}` : 'columnVisibility');
      if (visibility) {
        try {
          const savedVisibility = JSON.parse(visibility);
          this.tableHeaders.forEach(header => {
            const savedHeader = savedVisibility.find(h => h.key === header.key);
            if (savedHeader) {
              header.visible = savedHeader.visible;
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  beforeDestroy() {
    this.$vs.loading.close();
    clearTimeout(this.timeout)
    window.removeEventListener('resize', this.updateScreenWidth);
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/components/smnTable.scss";
</style>

<style scoped>
@media screen and (max-width: 767px) {
  .add-button-responsive-1 .add-button {
    margin: 1rem 0 0 0;
  }
}

.badge {
  display: inline-block;
  padding: 6px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.badge-red {
  background-color: #e40914;
  color: #fff;
}

.badge-green {
  background-color: #32A431;
  color: #fff;
}

.badge-yellow {
  background-color: #F7B500;
  color: #fff;
}
</style>