export default {
    namespaced: true,
    state: {
        caretakers: [],
        countries: [],
        municipalities: [],
        subject_eval_methods: [],
        competence_types: []
    },
    mutations: {
        setCaretakers(state, c) {
            state.caretakers = c
        },
        setCaretaker(state, c) {
            state.caretakers = [
                ...state.caretakers,
                c
            ]
        },
        setCountries (state, countries) {
            state.countries = countries.map((c) => {
                return c.name
            })
        },
        setMunicipalities (state, municipalities) {
            state.municipalities = municipalities.map((m) => {
                return m.name
            })
        },
        setSubjectEvalMethods (state, methods) {
            state.subject_eval_methods = methods
        },
        setCompetenceTypes (state, types) {
            state.competence_types = types
        }
    },
    actions: {
        caretakers ({commit}, payload) {
          commit('setCaretakers', payload)
        },
        setCaretaker ({commit}, caretaker) {
            commit('setCaretaker', caretaker)
        },
        countries ({commit}, countries) {
            commit('setCountries', countries)
        },
        municipalities ({commit}, municipalities) {
            commit('setMunicipalities', municipalities)
        },
        subjectEvalMethods ({commit}, methods) {
            commit('setSubjectEvalMethods', methods)
        },
        competenceTypes ({commit}, types) {
            commit('setCompetenceTypes', types)
        }
    },
    getters: {
        getCompetenceTypes: state => {
            return state.competence_types
        }
    }
}
