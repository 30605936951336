const userTypes = {
    'super_admin': {
        key: 'super_admin',
        label: 'Super Administratori',
        addLabel: 'Shto Super administrator',
        editLabel: 'Ndrysho super administrator',
        typeId: 1,
        canView: ['contracts', 'grade', 'schedule', 'dashboard', 'dashboard.home', 'evaluation.percentage', 'shifttime', 'substituteprofessors', 'reports', 'reports.home', 'classes', 'classes.student', 'classes.subject', 'academic_year', 'subjects', 'allUnits', 'units', 'units.absences', 'units.remarks', 'absences', 'absences_informed', 'remarks', 'teaching_unit', 'yearly_teaching_unit', 'users', 'users.super_admin', 'users.infirmary', 'users.admin', 'users.professor', 'users.management', 'users.low_management', 'users.caretaker', 'users.student', 'importing', 'evaluation_configuration', 'evaluation_methods', 'evaluation', 'user', 'user.documents', 'page', 'announcements', 'announcements.list', 'dailyprofessor', 'dailyprofessor.list', 'competences','competences_configuration', 'infirmary-health-cards', 'infirmary-overview-healthcards', 'overview-healthcard', 'infirmary-reports', 'vaccines', 'allergies', 'infirmary-therapies', 'application-usage-report', 'student-comments'],
        canAdd: ['contracts', 'grade', 'schedule', 'user', 'user.documents', 'classes', 'classes.student', 'shifttime', 'substituteprofessors', 'subject', 'units.absences', 'units.remarks', 'importing', 'evaluation_configuration', 'announcements', 'dailyprofessor', 'student-comment'],
        canEdit: ['contracts', 'grade', 'schedule', 'academic_year', 'semester','classes', 'classes.subject', 'shifttime', 'substituteprofessors', 'classes.student', 'classes.main_professor', 'user', 'user.data', 'subject', 'teaching_unit', 'absences', 'evaluation_configuration', 'evaluation', 'announcements', 'dailyprofessor', 'competences_configuration', 'student-comment'],
        canTransfer: ['transfer.student'],
        canExportContract: ['contracts'],
        canImportContract: ['contracts'],
        canDelete: ['contracts', 'grade','user.documents', 'schedule', 'classes.subject', 'classes.student', 'shifttime', 'substituteprofessors', 'teaching_unit', 'yearly_teaching_unit', 'units.absences', 'units.remarks', 'announcements', 'dailyprofessor', 'competences_configuration', 'classes', 'subject', 'units', 'evaluation_methods', 'student-comment']
    },
    'management': {
        key: 'management',
        label: 'Menaxhmenti',
        addLabel: 'Shto Menaxhment',
        editLabel: 'Ndrysho Menaxhment',
        typeId: 2,
        canView: ['contracts', 'grade', 'schedule', 'dashboard', 'dashboard.home', 'evaluation.percentage', 'shifttime', 'substituteprofessors', 'reports', 'reports.home', 'classes', 'classes.student', 'classes.subject', 'academic_year', 'subjects', 'allUnits', 'units', 'units.absences', 'units.remarks', 'absences', 'absences_informed', 'remarks', 'teaching_unit', 'yearly_teaching_unit', 'users', 'users.super_admin', 'users.admin', 'users.professor', 'users.management', 'users.low_management', 'users.caretaker', 'users.student', 'importing', 'evaluation_configuration', 'evaluation_methods', 'evaluation', 'user', 'user.documents', 'page', 'announcements', 'announcements.list', 'dailyprofessor', 'dailyprofessor.list', 'competences','competences_configuration', 'infirmary-health-cards', 'infirmary-overview-healthcards', 'overview-healthcard', 'infirmary-reports', 'vaccines', 'allergies', 'infirmary-therapies', 'application-usage-report', 'student-comments'],
        canAdd: ['contracts', 'grade', 'schedule', 'user', 'user.documents', 'classes', 'classes.student', 'shifttime', 'substituteprofessors', 'subject', 'units.absences', 'units.remarks', 'importing', 'evaluation_configuration', 'announcements', 'dailyprofessor', 'student-comment'],
        canEdit: ['contracts', 'grade', 'schedule', 'academic_year', 'semester','classes', 'classes.subject', 'shifttime', 'substituteprofessors', 'classes.student', 'classes.main_professor', 'user', 'user.data', 'subject', 'teaching_unit', 'absences', 'evaluation_configuration', 'evaluation', 'announcements', 'dailyprofessor', 'competences_configuration', 'student-comment'],
        canTransfer: ['transfer.student'],
        canExportContract: ['contracts'],
        canImportContract: ['contracts'],
        canDelete: ['contracts', 'grade','user.documents', 'schedule', 'classes.subject', 'classes.student', 'shifttime', 'substituteprofessors', 'teaching_unit', 'yearly_teaching_unit', 'units.absences', 'units.remarks', 'announcements', 'dailyprofessor', 'competences_configuration', 'classes', 'subject', 'units', 'evaluation_methods', 'student-comment']
    },
    'professor': {
        key: 'professor',
        label: 'Mësimdhënësit',
        addLabel: 'Shto Mësimdhënës',
        editLabel: 'Ndrysho Mësimdhënës',
        typeId: 3,
        canView: ['dashboard', 'schedule', 'dashboard.home', 'reports', 'reports.home', 'substituteprofessors', 'absences', 'absences_informed', 'remarks', 'classes', 'classes.student', 'classes.subject', 'teaching_unit', 'yearly_teaching_unit', 'units', 'units.absences', 'units.remarks', 'subjects', 'evaluation_methods', 'evaluation', 'user', 'page', 'announcements', 'announcements.list', 'dailyprofessor', 'dailyprofessor.list', 'competences', 'competences_configuration', 'competence', 'infirmary-health-cards', 'infirmary-therapies', 'student-comments'],
        canAdd: ['teaching_unit', 'schedule', 'yearly_teaching_unit', 'units', 'units.absences', 'substituteprofessors', 'units.remarks', 'evaluation_methods', 'competences_configuration', 'competence', 'student-comment'],
        canEdit: ['teaching_unit', 'schedule', 'units', 'units.remarks', 'units.absences', 'evaluation_methods', 'substituteprofessors', 'evaluation', 'absences', 'competences_configuration', 'competence', 'student-comment'],
        canDelete: ['teaching_unit', 'schedule', 'yearly_teaching_unit', 'units', 'units.absences', 'evaluation_methods', 'student-comment']
    },
    'student': {
        key: 'student',
        label: 'Nxënësit',
        addLabel: 'Shto Nxënës',
        editLabel: 'Ndrysho Nxënës',
        typeId: 4,
        canView: ['dashboard', 'schedule', 'dashboard.home', 'reports', 'reports.home', 'absences', 'absences_informed', 'remarks', 'caretaker_evaluation', 'user', 'page', 'announcements', 'announcements.list', 'infirmary-health-cards', 'infirmary-overview-healthcards', 'overview-healthcard', 'allergies', 'vaccines', 'infirmary-therapies', 'student-comments'],
        canAdd: [],
        canEdit: [],
        canDelete: []
    },
    'caretaker': {
        key: 'caretaker',
        label: 'Prindërit',
        typeId: 5,
        canView: ['contracts', 'dashboard', 'schedule', 'dashboard.home', 'reports', 'reports.home', 'absences', 'absences_informed', 'remarks', 'caretaker_evaluation', 'user', 'page', 'announcements', 'announcements.list', 'infirmary-health-cards', 'infirmary-overview-healthcards', 'overview-healthcard', 'allergies', 'vaccines', 'infirmary-therapies', 'student-comments'],
        canAdd: [],
        canEdit: [],
        canExportContract: ['contracts'],
        canImportContract: [],
        canDelete: []
    },
    'low_management': {
        key: 'low_management',
        label: 'Menaxhmenti i mesëm',
        addLabel: 'Shto Menaxhment te mesëm',
        editLabel: 'Ndrysho Menaxhment te mesëm',
        typeId: 6,
        canView: ['dashboard', 'schedule', 'dashboard.home', 'reports', 'reports.home', 'absences', 'absences_informed', 'remarks', 'classes', 'classes.student', 'classes.subject', 'teaching_unit', 'yearly_teaching_unit', 'units', 'units.absences', 'units.remarks', 'subjects', 'evaluation_methods', 'evaluation', 'user', 'page', 'announcements', 'announcements.list', 'dailyprofessor', 'dailyprofessor.list', 'competences', 'competences_configuration', 'competence', 'infirmary-health-cards', 'infirmary-therapies', 'student-comments'],
        canAdd: ['teaching_unit', 'schedule', 'yearly_teaching_unit', 'units', 'units.absences', 'units.remarks', 'evaluation_methods', 'competences_configuration', 'competence', 'student-comment'],
        canEdit: ['teaching_unit', 'schedule', 'yearly_teaching_unit', 'units', 'units.remarks', 'units.absences', 'evaluation_methods', 'evaluation', 'absences', 'competences_configuration', 'competence', 'student-comment'],
        canDelete: ['teaching_unit', 'schedule', 'yearly_teaching_unit', 'evaluation_methods', 'student-comment']
    },
    'admin': {
        key: 'admin',
        label: 'Administratori',
        addLabel: 'Shto administrator',
        editLabel: 'Ndrysho administrator',
        typeId: 7,
        canView: ['contracts', 'grade', 'schedule', 'dashboard', 'dashboard.home', 'evaluation.percentage', 'shifttime', 'substituteprofessors', 'reports', 'reports.home', 'classes', 'classes.student', 'classes.subject', 'academic_year', 'subjects', 'allUnits', 'units', 'units.absences', 'units.remarks', 'absences', 'absences_informed', 'remarks', 'teaching_unit', 'yearly_teaching_unit', 'users', 'users.admin', 'users.professor', 'users.management', 'users.low_management', 'users.caretaker', 'users.student', 'importing', 'evaluation_configuration', 'evaluation_methods', 'evaluation', 'user', 'user.documents', 'page', 'announcements', 'announcements.list', 'dailyprofessor', 'dailyprofessor.list', 'competences','competences_configuration', 'infirmary-health-cards', 'infirmary-therapies', 'student-comments'],
        canAdd: ['contracts', 'schedule', 'user', 'user.documents', 'classes', 'classes.student', 'shifttime', 'substituteprofessors', 'subject', 'importing', 'evaluation_configuration', 'announcements', 'dailyprofessor'],
        canEdit: ['contracts', 'schedule', 'academic_year', 'semester','classes', 'classes.subject', 'shifttime', 'substituteprofessors', 'classes.student', 'classes.main_professor', 'user', 'user.data', 'subject', 'teaching_unit', 'absences', 'evaluation_configuration', 'announcements', 'dailyprofessor', 'competences_configuration', 'student-comment'],
        canTransfer: ['transfer.student'],
        canExportContract: ['contracts'],
        canImportContract: ['contracts'],
        canDelete: ['contracts', 'user.documents', 'schedule', 'classes.subject', 'classes.student', 'shifttime', 'substituteprofessors', 'teaching_unit', 'yearly_teaching_unit', 'announcements', 'dailyprofessor', 'competences_configuration', 'classes', 'subject', 'evaluation_methods']
    },
    'infirmary': {
        key: 'infirmary',
        label: 'Infermier',
        addLabel: 'Shto infermier',
        editLabel: 'Ndrysho infermier',
        typeId: 8,
        canView: ['vaccines', 'allergies', 'infirmary', 'infirmary-health-cards', 'infirmary-overview-healthcards', 'overview-healthcard', 'infirmary-therapies', 'infirmary-reports', 'announcements', 'announcements.list', 'dashboard', 'dashboard.home'],
        canAdd: ['vaccines', 'allergies', 'infirmary-therapies'],
        canEdit: ['vaccines', 'allergies', 'infirmary-overview-healthcards', 'overview-healthcard', 'infirmary-therapies'],
        canDelete: ['vaccines', 'allergies', 'infirmary-therapies'],
    },
}
 
export default userTypes
