import Http from './http'

function get () {
    return Http.get(`/auth/final_evaluation`)
}

function getConfigById (config_id) {
    return Http.get(`/auth/final_evaluation/${config_id}`)
}

function addConfig (params) {
    return Http.post(`/auth/final_evaluation`, params)
}

function updateConfig (params, config_id) {
    return Http.put(`/auth/final_evaluation/${config_id}`, params)
}

function getConfigByClassNumber (class_number) {
    return Http.get(`/auth/final_evaluation/class_number/${class_number}`)
}

function getSubjectMethods (params) {
    return Http.get(`/auth/subject-evaluation-method?${params}`)
}

function getMethods (params) {
    return Http.get(`/auth/evaluation-method?${params}`)
}

function getMethodById (method_id) {
    return Http.get(`/auth/evaluation-method/${method_id}`)
}

function addMethod (params) {
    return Http.post(`/auth/evaluation-method`, params)
}

function updateMethod (params, method_id) {
    return Http.put(`/auth/evaluation-method/${method_id}`, params)
}

function deleteMethod (method_id) {
    return Http.delete(`/auth/evaluation-method/${method_id}`)
}

function getSubjectClasses () {
    return Http.get(`/auth/evaluation/list_subject_class`)
}

function getDetails (subject_id, class_id, class_number) {
    return Http.get(`/auth/evaluation/get/${subject_id}/${class_id}/${class_number}`)
}

function addEvaluation (params) {
    return Http.post(`/auth/evaluation`, params)
}

function editEvaluation (params, evaluation_id) {
    return Http.put(`/auth/evaluation/${evaluation_id}`, params)
}

function getClassEvaluation (class_id) {
    return Http.get(`/auth/evaluation/get_by_class/${class_id}`)
}

function getStudentEvaluation (student_id, class_id) {
    return Http.get(`/auth/evaluation/get_by_student_class/${student_id}/${class_id}`)
}

function getStudentEvaluationClasses (student_id) {
    return Http.get(`/auth/evaluation/get_by_student/${student_id}`)
}

function getStudentEvaluationDetails (student_id, subject_id, final_evaluation_id, semester_id) {
    return Http.get(`/auth/caretakers/evaluationDetails/${student_id}/${subject_id}/${final_evaluation_id}/${semester_id}`)
}

function getActivePeriod () {
    return Http.get(`/auth/evaluation-methods/get_active_period`)
}

function updateOneOrMoreGrade (params) {
    return Http.post(`/auth/one/or/more/grades`, params)
}

function getActiveEvaluationMethods (subject_id) {
    return Http.get(`/auth/getactive/evaluation/methods/${subject_id}`)
}

function getSubjectWithoutEvaluationMethods (count) {
    return Http.get(`/auth/evaluation-methods/subjects-without-evaluation?getSubjectCount=${count}`)
}

export {
    get,
    getConfigById,
    addConfig,
    updateConfig,
    getConfigByClassNumber,
    getSubjectMethods,
    getMethods,
    getMethodById,
    addMethod,
    updateMethod,
    deleteMethod,
    getSubjectClasses,
    getDetails,
    addEvaluation,
    editEvaluation,
    getClassEvaluation,
    getStudentEvaluation,
    getStudentEvaluationClasses,
    getStudentEvaluationDetails,
    getActivePeriod,
    updateOneOrMoreGrade,
    getActiveEvaluationMethods,
    getSubjectWithoutEvaluationMethods,
}
