import moment from 'moment';

const filters = {
  smnDate(value) {
    if (value) {
      return moment(value).format('DD MMMM YYYY');
    }
  },
  smnTime(value) {
    if (value) {
      const timeMoment = moment(value, 'HH:mm');
      return timeMoment.format('HH:mm');
    }
  },
  contractStatus(value) {
    if (value && value == 0 || value == '0') {
      return "E ringjeneruar";
    }
    else if (value && value == 1 || value == '1') {
      return "E pa nënshkruar";
    }
    else if (value && value == 2 || value == '2') {
      return "E nënshkruar";
    }
    return ''
  },
  capitalize(value) {
    if (!value) return ''
    value = value.toString()
    const arr = value.split(' ')
    const capitalized_array = []
    arr.forEach((word) => {
      const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalized_array.push(capitalized)
    })
    return capitalized_array.join(' ')
  },
  title(value, replacer = '_') {
    if (!value) return ''
    value = value.toString()

    const arr = value.split(replacer)
    const capitalized_array = []
    arr.forEach((word) => {
      const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalized_array.push(capitalized)
    })
    return capitalized_array.join(' ')
  },
  truncate(value, limit) {
    if (value && value.length <= limit) {
      return value;
    } else if (value && value.length > limit) {
      return value.substring(0, limit) + '...';
    }
    return value;
  },
  tailing(value, tail) {
    return value + tail
  },
  time(value, is24HrFormat = false) {
    if (value) {
      const date = new Date(Date.parse(value))
      let hours = date.getHours()
      const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
      if (!is24HrFormat) {
        const time = hours > 12 ? 'AM' : 'PM'
        hours = hours % 12 || 12
        return `${hours}:${min} ${time}`
      }
      return `${hours}:${min}`
    }
  },
  date(value, fullDate = false) {
    value = String(value)
    const date = value.slice(8, 10).trim()
    const month = value.slice(4, 7).trim()
    const year = value.slice(11, 15)

    if (!fullDate) return `${date} ${month}`
    else return `${date} ${month} ${year}`
  },
  month(val, showYear = true) {
    val = String(val)

    const regx = /\w+\s(\w+)\s\d+\s(\d+)./
    if (!showYear) {
      return regx.exec(val)[1]
    } else {
      return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`
    }
  },
  csv(value) {
    return value.join(', ')
  },
  filter_tags(value) {
    return value.replace(/<\/?[^>]+(>|$)/g, '')
  },
  k_formatter(num) {
    return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
  },
  listDateFormat(date) {
    return moment(date).format('DD-MM-YYYY')
  },
  smnAbsenceStatus(value) {
    if (value) {
      if (value === 'unpermitted') {
        return 'E pa arsyeshme'
      } else if (value === 'undefined') {
        return 'E pa definuar'
      }
      return 'E arsyeshme'
    }
    return ''
  },
  smnClassNumberFilter(value) {
    if (value) {
      if (value === 13) {
        return 'Grup'
      }
      return value
    }
    return ''
  },
  smnActiveStatusFilter(value) {
    var returnVal = ''
    if (value && value == 1) {
      returnVal = 'Aktiv'
    }
    else if (value == 0) {
      returnVal = 'Jo aktiv'
    }
    return returnVal;
  },
  smnDayOfWeek(value) {
    var returnVal = ''
    if (value === 1 || value === '1') {
      returnVal = 'E Hënë';
    } else if (value === 2 || value === '2') {
      returnVal = 'E Martë';
    } else if (value === 3 || value === '3') {
      returnVal = 'E Mërkurë';
    } else if (value === 4 || value === '4') {
      returnVal = 'E Enjte';
    } else if (value === 5 || value === '5') {
      returnVal = 'E Premte';
    }
    return returnVal;
  },
  smnClassTime(value) {
    var returnVal = ''
    if (value === 1 || value === '1') {
      returnVal = 'Ora parë';
    } else if (value === 2 || value === '2') {
      returnVal = 'Ora dytë';
    } else if (value === 3 || value === '3') {
      returnVal = 'Ora tretë';
    } else if (value === 4 || value === '4') {
      returnVal = 'Ora katërt';
    } else if (value === 5 || value === '5') {
      returnVal = 'Ora pestë';
    } else if (value === 6 || value === '6') {
      returnVal = 'Ora gjashtë';
    } else if (value === 7 || value === '7') {
      returnVal = 'Ora shtatë';
    } else if (value === 8 || value === '8') {
      returnVal = 'Ora tetë';
    }
    return returnVal;
  },
  arabicToRoman(number) {
    const romans = {
      1: 'I',
      2: 'II',
      3: 'III',
      4: 'IV',
      5: 'V',
      6: 'VI',
      7: 'VII',
      8: 'VIII',
      9: 'IX',
      10: 'X',
      11: 'XI',
      12: 'XII'
    };

    if (number <= 0 || number > 12) {
      return 'Invalid';
    }

    return 'KL-' + romans[number];
  },
  fromDateToDateCompare(fromDate, toDate) {
    const today = new Date();
    const parsedFromDate = new Date(fromDate);

    if (parsedFromDate > today) {
      return {
        isValid: false,
        errorMessage: "'Data nga' nuk mund të jetë më e madhe se data e sotme."
      };
    }

    if (toDate && new Date(toDate) < parsedFromDate) {
      return {
        isValid: false, 
        errorMessage: "'Data deri' nuk mund të jetë më e vogël se 'Data nga'."
      };
    }

    return {
      isValid: true, 
      errorMessage: ""
    };
  }
};

export default {
  install(app) {
    Object.keys(filters).forEach((key) => {
      app.config.globalProperties[`$${key}`] = filters[key];
    });
  },
};
