import Http from './http'

function get(params) {
    return Http.get(`/auth/subject?${params}`)
}

function getById(subjectId) {
    return Http.get(`/auth/subject/${subjectId}`)
}

function getSubjectsByUserId(params) {
    return Http.get(`/auth/subjects/professor`, { params });
}

function create(params) {
    return Http.post('/auth/subject', params)
}

function update(params, subjectId) {
    return Http.put(`/auth/subject/${subjectId}`, params)
}

function _delete(subject_id) {
    return Http.delete(`/auth/subject/${subject_id}`)
}

function getCategorySubjects() {
    return Http.get(`/auth/category_subjects`)
}

export {
    get,
    getSubjectsByUserId,
    create,
    update,
    getById,
    _delete,
    getCategorySubjects
}