import Http from './http'


function getUsers (type, params) {
    return Http.get(`/auth/${type}?${params}`)
}

function getUser (type, id) {
    return Http.get(`/auth/${type}/${id}`)
}

function create (params) {
    return Http.post('/auth/user/save', params)
}

function update (params, userId) {
    return Http.post(`/auth/user/edit/${userId}`, params)
}

function importUsers (params) {
    return Http.post(`/auth/import`, params)
}

function updateUserPassword (params) {
    return Http.put('/auth/user/update/password', params)
}

function _deleteUser (user_id) {
    return Http.delete(`/auth/user/destroy/${user_id}`)
}

function updateUserStatus (params) {
    return Http.put(`/auth/user/status/update`, params)
}

function getNotUsedProfessors(params){
    return Http.get(`/auth/professor/notUsed/professor/list?${params}`);
}


export {
    getUsers,
    getUser,
    create,
    update,
    importUsers,
    updateUserPassword,
    _deleteUser,
    updateUserStatus,
    getNotUsedProfessors,
}