<template>
    <form @submit.prevent="search">
        <vs-input ref="searchInput" icon-pack="feather" icon="icon-search" :placeholder="placeholder"
            v-model="searchQuery" class="w-full" @click="openDropdown" @keyup="search" v-on:icon-click="search" />

        <div v-if="showResults" class="autocomplete-results mt-3" :style="{ width: dropdownWidth + 'px' }">
            <div v-for="(item, index) in searchResults" :key="index" @click="selectItem(item)"
                class="autocomplete-item">
                <p>{{ displayItem(item) }}</p>
            </div>
        </div>

        <div v-if="showNotFound && dropdownWidth > 0" class="autocomplete-results flex justify-center text-red mt-3"
            :style="{ width: dropdownWidth + 'px' }">
            <p>Asnjë resultat!</p>
        </div>
        <input type="submit" class="hidden" />
    </form>
</template>

<script>
export default {
    name: 'SMNSearchInput',
    props: {
        searchResults: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'Kërko...'
        },
        displayKeys: {
            type: Array,
            default: () => ['full_name']
        },
    },
    watch: {
        'searchResults': function () {
            const inputWidth = this.$refs.searchInput?.$el?.offsetWidth;
            this.dropdownWidth = inputWidth;

            if (this.searchResults.length > 0) {
                this.showResults = true;
                this.showNotFound = false;
            } else {
                this.showResults = false;
                this.showNotFound = true;
            }
        },
        "searchQuery": function (newValue) {
            if (!newValue) {
                this.showResults = false;
                this.showNotFound = false;

                this.students = []
            }
        },
    },
    data() {
        return {
            searchQuery: '',
            showResults: false,
            showNotFound: false,
            dropdownWidth: 0
        };
    },
    mounted() {
        document.addEventListener("click", this.closeDropdownOnClickOutside);
    },
    methods: {
        search() {
            this.$emit('search', this.searchQuery);
        },
        openDropdown() {
            if (this.searchQuery && this.searchResults.length > 0) {
                this.showResults = true;
                this.showNotFound = false;
            } else if (this.searchQuery && this.searchResults.length === 0) {
                this.showResults = false;
                this.showNotFound = true;
            }
        },
        closeDropdownOnClickOutside(event) {
            if (!this.$refs.searchInput?.$el.contains(event.target)) {
                this.showResults = false;
                this.showNotFound = false;
            }
        },
        selectItem(item) {
            this.$emit('item-selected', item);

            this.searchQuery = this.displayKeys.map(key => item[key]).join(' - ');
        },
        displayItem(item) {
            return this.displayKeys.map(key => item[key]).join(' - ');
        },        
    },
    beforeDestroy() {
        document.addEventListener("click", this.closeDropdownOnClickOutside);
    }
};
</script>

<style scoped>
.autocomplete-results {
    position: absolute;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .4) !important;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
    border-radius: 5px;
    max-height: 175px;
    min-height: 75px;
    overflow-y: auto;
    z-index: 1000;
}

.autocomplete-item {
    padding: 5px;
    margin-left: 15px;
    cursor: pointer;
}
</style>