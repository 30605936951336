import Http from './http'

function get (params) {
    return Http.get(`/auth/student-remark?${params}`)
}

function filter (params) {
    return Http.get(`/auth/filter_remarks`,  {
        params: params
    })
}

function getById (remark_id) {
    return Http.get(`/auth/student-remark/${remark_id}`)
}

function create (params) {
    return Http.post(`/auth/student-remark`, params)
}

function update (params, absence_id) {
    return Http.put(`/auth/student-remark/${absence_id}`, params)
}

function _delete (absence_id) {
    return Http.delete(`/auth/student-remark/${absence_id}`)
}

export {
    get,
    filter,
    getById,
    create,
    update,
    _delete
}