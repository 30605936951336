import Http from './http'

function getAll () {
    return Http.get(`/auth/get_professors`)
}

function getDailyProfessors (params) {
    return Http.get(`/auth/daily_professor?${params}`)
}

function createDaily (params) {
    return Http.post('/auth/daily_professor', params)
}

function updateDaily (id, params) {
    return Http.put(`/auth/daily_professor/${id}`, params)
}

function deleteDaily (id) {
    return Http.delete(`auth/daily_professor/${id}`)
}
    
function getDailyById (id) {
    return Http.get(`/auth/daily_professor/${id}`)
}

function commentOnDaily (id, params) {
    return Http.put(`/auth/daily_professor/comments/${id}`, params)
}

export {
    getAll,
    getDailyById,
    getDailyProfessors,
    createDaily,
    updateDaily,
    deleteDaily,
    commentOnDaily
}