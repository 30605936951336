<template>
    <vs-icon :type="type" :icon-pack="iconPack" :icon="icon" :size="size" :color="color"></vs-icon>
</template>

<script>
export default {
    functional: true,
    name: 'feather-icon',
    props: {
        iconPack: { type: String, required: true, default: '' },
        icon: { type: String, required: true, default: '' },
        type: { type: String, default: 'flat' },
        size: { type: String, default: '1.3rem' },
        color: { type: String, default: '#626262' },
    }
}
</script>
