import Http from './http'

function get (params) {
    return Http.get(`/auth/school_half_years?${params}`)
}

function getById(halfYearId) {
	return Http.get(`/auth/school_half_years/${halfYearId};`)	
}

function create(params) {
	return Http.post('/auth/school_half_years', params)
}

function update(halfYearId, params) {
	return Http.put(`/auth/school_half_years/${halfYearId}`, params)
}

function close() {
	return Http.post('/auth/school_half_years/close')
}

function _delete(halfYearId) {
	return Http.delete(`/auth/school_half_years/${halfYearId}`)
}

export {
	get,
	getById,
	create,
	update,
	_delete,
	close,
}