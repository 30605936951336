import Http from './http';

function getShiftTimes(params) {
    return Http.get(`/auth/shift-times?${params}`)
}

function getShiftTimeById(id) {
    return Http.get(`/auth/shift-times/${id}`)
}

function updateShiftTime(id, params) {
    return Http.put(`/auth/shift-times/${id}`, params)
}

function saveShiftTime(params) {
    return Http.post(`/auth/shift-times`, params)
}

function deleteShiftTime(id) {
    return Http.delete(`/auth/shift-times/${id}`)
}

//timetables
function getTimeTablesByClass(id, params) {
    return Http.get(`/auth/timetables/${id}`, { params })
}

function updateTimeTable(id, params) {
    return Http.put(`/auth/timetables/${id}`, params)
}

function getClassNumberForProfessor() {
    return Http.get(`/auth/timetable/by-classNumber`);
}

function getTimetableForProfessor(params) {
    return Http.get(`/auth/timetable/for-professor`, { params });
}

export {
    getShiftTimes,
    getShiftTimeById,
    updateShiftTime,
    updateTimeTable,
    saveShiftTime,
    deleteShiftTime,
    getTimeTablesByClass,
    getClassNumberForProfessor,
    getTimetableForProfessor,
}