import Http from './http'


// competences
function getList () {
    return Http.get(`/auth/competence/list_subject_class`)
}

function getDetails (class_id, subject_id) {
    return Http.get(`/auth/competences/competence-evaluation/${subject_id}/${class_id}`)
}

function getStudentEvaluation (subject_id, class_id, student_id) {
    return Http.get(`/auth/competences/competence-evaluation-student/${subject_id}/${class_id}/${student_id}`)
}

function addStudentEvaluation (params) {
    return Http.post(`/auth/competences/create-evaluation`, params)
}

function updateStudentEvaluation (params, evaluation_id) {
    return Http.put(`/auth/competences/update-evaluation/${evaluation_id}`, params)
}

function getClassEvaluation (class_id) {
    return Http.get(`/auth/competences/group-competences-class/${class_id}`)
}

//competences config
function get () {
    return Http.get(`/auth/competences/get-professor-competences`)
}

function getSubjectCompetenceById (subject_id) {
    return Http.get(`/auth/competences/professor-competences/${subject_id}`)
}

function getSubjects () {
    return Http.get(`/auth/subjects_competences`)
}

function getCompetenceTypes () {
    return Http.get(`/auth/competence`)
}

function addConfig (params) {
    return Http.post('/auth/competences/create-professor-competences', params)
}

function updateConfig (params) {
    return Http.put('/auth/competences/update-professor-competences', params)
}

function _deleteProfessorCompetence (subject_id) {
    return Http.delete(`/auth/competences/destroy-professor-competences/${subject_id}`)
}

function exportStudentCompetence (class_id, student_id) {
    return Http.get(`/auth/competence/student/export/${class_id}/${student_id}`, {
        responseType: 'blob'
    })
}

export {
    get,
    getList,
    getDetails,
    getStudentEvaluation,
    addStudentEvaluation,
    updateStudentEvaluation,
    getClassEvaluation,
    getSubjects,
    getSubjectCompetenceById,
    getCompetenceTypes,
    addConfig,
    updateConfig,
    _deleteProfessorCompetence,
    exportStudentCompetence
}