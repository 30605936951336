import Http from './http'

function get (params) {
    return Http.get(`/auth/unit?${params}`)
}

function getByUser (userId, page = 1, class_id, subjectId) {
    return Http.get(`/auth/unit/by_user/${userId}?per_page=10&page=${page}&class_id=${class_id}&subject_id=${subjectId}`)
}

function getById (unitId) {
    return Http.get(`/auth/unit/${unitId}`)
}

function create (params) {
    return Http.post(`/auth/unit`, params)
}

function update (params, unitId) {
    return Http.put(`/auth/unit/${unitId}`, params)
}

function _delete (unitId) {
    return Http.delete(`/auth/unit/${unitId}`)
}

export {
    get,
    getByUser,
    getById,
    create,
    update,
    _delete
}
