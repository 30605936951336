/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: SMN
  Author: Freaks
  Author URL: https://smn.pixellat.com
==========================================================================================*/



import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'
import i18nData from './i18nData'


const i18n = createI18n({
  locale: 'al', // set default locale
  messages: i18nData
})

export default i18n;