/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      // DASHBOARDS
      {title: "Shtepi",        url: "/dashboard/home",                icon: "HomeIcon",           is_bookmarked: true},
    ]
  },
  files: {
    key:"file_name",
    data: [
      { file_name: "Joe's CV",         from:"Stacy Watson",    file_ext:"doc", size:"1.7 mb"  },
      { file_name: "Passport Image",   from:"Ben Sinitiere",   file_ext:"jpg", size:" 52 kb"  },
      { file_name: "Questions",        from:"Charleen Patti",  file_ext:"doc", size:"1.5 gb"  },
      { file_name: "Parenting Guide",  from:"Doyle Blatteau",  file_ext:"doc", size:"2.3 mb"  },
      { file_name: "Class Notes",      from:"Gwen Greenlow",   file_ext:"doc", size:" 30 kb"  },
      { file_name: "Class Attendance", from:"Tom Alred",       file_ext:"xls", size:"52 mb"   },
      { file_name: "Company Salary",   from:"Nellie Dezan",    file_ext:"xls", size:"29 mb"   },
      { file_name: "Company Logo",     from:"Steve Sheldon",   file_ext:"jpg", size:"1.3 mb"  },
      { file_name: "Crime Rates",      from:"Sherlock Holmes", file_ext:"xls", size:"37 kb"   },
      { file_name: "Ulysses",          from:"Theresia Wrenne", file_ext:"pdf", size:"7.2 mb"  },
      { file_name: "War and Peace",    from:"Goldie Highnote", file_ext:"pdf", size:"10.5 mb" },
      { file_name: "Vedas",            from:"Ajay Patel",      file_ext:"pdf", size:"8.3 mb"  },
      { file_name: "The Trial",        from:"Sirena Linkert",  file_ext:"pdf", size:"1.5 mb"  },
    ]
  },
  contacts: {
    key:"name",
    data: [
      // { img: require("@/assets/images/portrait/small/avatar-s-4.jpg"),  name: "Rena Brant",          email: "nephrod@preany.co.uk",     time: "21/05/2019" },
    ]
  },
}
/* eslint-enable */
