<template>
    <span >
        <slot v-if="hasAccess"></slot>
    </span>
</template>

<script>

import userTypes from '../views/users/data'

export default {
    name: 'SmnPermission',
    props: {
        type: {
            type: String,
            default: '',
            required: true
        },
        can: {
            type: String,
            default: '',
            required: true
        }
    },
    data () {
        return {
            hasAccess: false
        }
    },
    mounted () {
        switch (this.can) {
            case 'view': 
                this.canUserView()
                break;
            case 'add': 
                this.canUserAdd()
                break;
            case 'edit':
                this.canUserEdit()
                break;
            case 'transfer':
                this.canUserTransfer()
            break;
            case 'importContract':
                this.canUserImportContract()
            break;
            case 'exportContract':
                this.canUserExportContract()
            break;
            case 'delete':
                this.canUserDelete()
                break;
            default:
                this.hasAccess = false
        }
    },
    methods: {
        canUserView () {
            this.hasAccess = this.userTypePermissions.canView.includes(this.type)
        },
        canUserAdd () {
            this.hasAccess = this.userTypePermissions.canAdd.includes(this.type)
        },
        canUserEdit () {
            this.hasAccess = this.userTypePermissions.canEdit.includes(this.type)
        },
        canUserTransfer() {
            this.hasAccess = this.userTypePermissions.canTransfer.includes(this.type)
        },
        canUserExportContract() {
            this.hasAccess = this.userTypePermissions.canExportContract.includes(this.type)
        },
        canUserImportContract() {
            this.hasAccess = this.userTypePermissions.canImportContract.includes(this.type)
        },
        canUserDelete () {
            this.hasAccess = this.userTypePermissions.canDelete.includes(this.type)
        }
    },
    computed: {
        userTypeId () {
            return this.$store.getters['auth/getUserType']
        },
        userTypePermissions () {
            if (this.userTypeId) {
                return userTypes[this.userTypeId]
            }

            return null
        }
    }
}
</script>