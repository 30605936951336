import Http from './http'

function get (params) {
    return Http.get(`/auth/absence?${params}`)
}

function filter (params) {
    return Http.get(`/auth/absences/filter`,  { params: params })
}

function filterInformed(params) {
    return Http.get(`/auth/informed-absences/filter`,  { params: params })
}

function getById (absence_id) {
    return Http.get(`/auth/absence/${absence_id}`)
}

function getInformedById (absence_id) {
    return Http.get(`/auth/informed-absence/${absence_id}`)
}

function justify (params, absence_id) {
    return Http.put(`/auth/absence/justify/${absence_id}`, params)
}

function getStudentList (class_id) {
    return Http.get(`/auth/absence/student_list/${class_id}`)
}

function create (params) {
    return Http.post(`/auth/absence`, params)
}

function createInformed (params) {
    return Http.post(`/auth/informed-absence`, params)
}

function update (params, absence_id) {
    return Http.put(`/auth/absence/${absence_id}`, params)
}

function updateInformed (params, absence_id) {
    return Http.put(`/auth/informed-absence/${absence_id}`, params)
}

function _delete (absence_id) {
    return Http.delete(`/auth/absence/${absence_id}`)
}

function exportAbsences (params) {
    return Http.get(`/auth/absences/filter`,  {
        params: params,
        responseType: 'blob'
    })
}

export {
    get,
    filter,
    filterInformed,
    getStudentList,
    getById,
    getInformedById,
    justify,
    create,
    createInformed,
    update,
    updateInformed,
    _delete,
    exportAbsences
}