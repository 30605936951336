function getUserType (type) {
  switch (type) {
    case '1':
        return 'super_admin'

    case '2':
        return 'management'

    case '3':
      return 'professor'
    
    case '4':
      return 'student'
      
    case '5':
      return 'caretaker'

    case '6':
      return 'low_management'

    case '7':
      return 'admin'
      
    case '8':
      return 'infirmary'

    default:
        return null
  }
}

export default {
    namespaced: true,
    state: {
      user: null
    },
    mutations: {
      setUser (state, payload) {
        if (payload) {
          let user = payload
          user.initials = `${user.first_name.slice()[0]}${user.last_name.slice()[0]}`
          user.smn_user_type = getUserType(user.type)

          if (user.academic_years.length) {
            user.activeAcademicYear = user.academic_years[0].id
          }

          state.user = user
        } else {
          state.user = null
        }
      },
      setAcademicYear (state, yearId) {
        state.user.activeAcademicYear = yearId
        state.user = {
          ...state.user,
          activeAcademicYear: yearId
        }
      },
      setCaretakerChildren (state, children) {
        state.user = {
          ...state.user,
          children: children,
          child: (children.length) ? children[0] : null
        }
      },
      setCaretakerChild (state, child) {
        state.user = {
          ...state.user,
          child: child
        }
      }
    },
    actions: {
      login ({commit}, payload) {
        commit('setUser', payload)
      },
      setAcademicYear ({commit}, payload) {
        commit('setAcademicYear', payload)
      },
      setCaretakerChildren ({commit}, payload) {
        commit('setCaretakerChildren', payload)
      },
      setCaretakerChild ({commit}, payload) {
        commit('setCaretakerChild', payload)
      }
    },
    getters: {
      getUserType: state => {
        return (state.user) ? state.user.smn_user_type : null
      },
      getUser: state => {
        return state.user
      },
      getCaretakerChildren: state => {
        return state.user.children
      },
      getCaretakerChild: state => {
        return state.user.child
      }
    }
}
