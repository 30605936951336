const InfirmaryModule = () => import('./InfirmaryModule.vue')
const HealthCardsList = () => import('./views/healthcards/HealthCardsList.vue')
const OverviewHealthCardsList = () => import('./views/overview-healthcards/OverviewHealthCardsList.vue')
const ViewOrEditOverviewHealthCards = () => import('./views/overview-healthcards/components/ViewEditOverviewHealthCards.vue')
const TherapiesList = () => import('./views/therapy/TherapiesList.vue')
const InfirmaryReports = () => import('./views/reports/Reports.vue')

export const InfirmaryRoutes = [
    {
        path: '/infirmary',
        component: InfirmaryModule,
        name: 'infirmary',
        children: [
            {
                path: 'health-cards',
                component: HealthCardsList,
                name: 'infirmary-health-cards',
                meta: {
                    authRequired: true
                }
            },
            {
                path: 'overview-healthcards',
                component: OverviewHealthCardsList,
                name: 'infirmary-overview-healthcards',
                meta: {
                    authRequired: true
                },
            },
            {
                path: 'overview-healthcards/:mode/:id',
                component: ViewOrEditOverviewHealthCards,
                name: 'overview-healthcard',
                meta: {
                    authRequired: true
                },
                props: (route) => ({
                    overviewHealthCardId: parseInt(route.params.id),
                    viewMode: route.params.mode === 'view'
                }),
            },
            {
                path: 'therapies',
                component: TherapiesList,
                name: 'infirmary-therapies',
                meta: {
                    authRequired: true
                },
            },
            {
                path: 'reports',
                component: InfirmaryReports,
                name: 'infirmary-reports',
                meta: {
                    authRequired: true
                },
            },
        ]
    }
];