<template>
	<div class="vx-input-error flex" v-if="error && error.length">{{error[error.length-1]}}</div>
</template>

<script>
export default {
    props: {
        error : {
            type: Array,
            default: function () {
                return []
            }
        },
    },
	name: "vx-input-error",
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxInputError.scss";
</style>
