import Http from './http'

function getContracts(query, params) {
    return Http.get(`/auth/student-contracts?${query}`, { params: params });
}

function getContractById(id) {
    return Http.get(`/auth/student-contracts/${id}`);
}

function saveContract(params) {
    return Http.post(`/auth/student-contracts`, params)
}

function updateContract(id, params) {
    return Http.put(`/auth/student-contracts/${id}`, params)
}

function importSignedContract(params) {
    return Http.post(`/auth/student-contracts/import-pdf`, params)
}

function deleteContract(id) {
    return Http.delete(`/auth/student-contracts/${id}`)
}

function downloadContract(id) {
    return Http.get(`/auth/student-contracts/download/${id}`, { responseType: 'blob' })
}

function getStudentsOrParentsBySearch (search, isParent) {
    return Http.get(`/auth/student-contracts/students/${search}?isParent=${isParent}`)
}

function getParentsByStudent (studentId) {
    return Http.get(`/auth/student-contracts/student-caretakers/${studentId}`)
}

function getStudentsByParent (parentId) {
    return Http.get(`/auth/student-contracts/caretaker-students/${parentId}`)
}

function exportTableData (contractType, params) {
    return Http.get(`/auth/student-contracts?${contractType}`, {
        params: params,
        responseType: 'blob'
    })
}

function getAcademicYears (params) {
    return Http.get(`/auth/student-contract/academic-years`, { params: params})
}

export {
    getContracts,
    getContractById,
    saveContract,
    updateContract,
    importSignedContract,
    deleteContract,
    downloadContract,
    getStudentsOrParentsBySearch,
    getParentsByStudent,
    getStudentsByParent,
    exportTableData,
    getAcademicYears,
}