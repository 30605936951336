import Http from './http'

function get (params) {
    return Http.get(`/auth/teachingUnit?${params}`)
}

function getById (teachingUnitId) {
    return Http.get(`/auth/teachingUnit/${teachingUnitId}`)
}

function getDependencies () {
    return Http.get(`/auth/unit/dependencies/create`)
}

function getBySubject (subject_id, user_id) {
    return Http.get(`/auth/teachingUnit/${subject_id}/${user_id}`)
}

function create (params) {
    return Http.post(`/auth/teachingUnit`, params)
}

function update (params, teachingUnitId) {
    return Http.put(`/auth/teachingUnit/${teachingUnitId}`, params)
}

function _delete (teachingUnitId) {
    return Http.delete(`/auth/teachingUnit/${teachingUnitId}`)
}

function importTeachingUnits (subject_id, params) {
    return Http.post(`/auth/import/teaching-units/${subject_id}`, params)
}

function importYearlyDocument (params) {
    return Http.post(`/auth/teaching-unit/upload/files`, params)
}

export {
    get,
    getById,
    getDependencies,
    getBySubject,
    create,
    update,
    _delete,
    importTeachingUnits,
    importYearlyDocument
}