<template>
	<div class="smn-datepicker">
		<div class="vx-row">
			<div class="vx-col flex-1">
				<Datepicker ref="birthdayPicker" class="vs-input--input normal" :monday-first="true"
					inputFormat="dd/MM/yyyy" @selected="selectedDate" v-model="date"
					:disabled-dates="{ dates: disabledDates }" :placeholder="placeholder" :disabled="disabled"/>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vue3-datepicker'

export default {
	name: 'SMNDatepicker',
	components: {
		Datepicker,
	},
	props: {
		selectedDate: {
			default: null,
		},
		placeholder: { 
			type: String, default: 'Data' 
		},
		disabledDates: {
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		if (this.selectedDate) {
			// this.date = moment(this.selectedDate).format('DD/MM/YYYY');
			this.date = moment(this.selectedDate).toDate();
		}
	},
	data() {
		return {
			date: null,
		};
	},
	watch: {
		selectedDate: function (val) {
			if (val) {
				// this.date = moment(val).format('DD/MM/YYYY');
				this.date = moment(val).toDate();
			}
			else {
				this.date = null;
			}
		},
		date: function (val) {
			if (!this.isDateValid(val)) {
				// this.date = null;
				this.$emit('updated', '');
				return;
			}

			// this.$emit('updated', moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
			this.$emit('updated', moment(val).format('YYYY-MM-DD'));
		},
	},
	methods: {
		validate() {
			if (!this.isDateValid(this.date)) {
				// this.date = null;
				this.$emit('updated', '');
				return;
			}

			// this.$emit('updated', moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
			this.$emit('updated', moment(this.date).format('YYYY-MM-DD'));
		},
		isDateValid(date) {
			// const isValid = moment(date, 'DD/MM/YYYY', true).isValid();
			const isValid = moment(date).isValid();
			return isValid;
		},
	},
	computed: {
		selected() {
			// return this.date;
			// return this.date ? moment(this.date).format('DD/MM/YYYY') : null;
		},
		placeholderText() {
			return this.date ? "" : "Data";
		},
	},
};
</script>

<style lang="scss">
.v3dp__popout {
	position: absolute !important;
	z-index: 9999 !important;
	// top: -267px !important;
	left: 0% !important;
	// transform: translate(-80%, 0px);
}

.vs-input--input.normal::placeholder {
	font-size: 14px !important;
	/* Change the font size to your desired value */
	color: rgba(0, 0, 0, .4) !important;
}
</style>
