import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
import VxCard  from './components/vx-card/VxCard.vue'
import VxList  from './components/vx-list/VxList.vue'
import VxBreadcrumb  from './layouts/components/VxBreadcrumb.vue'
import VxInputGroup  from './components/vx-input-group/VxInputGroup.vue'
import VxInputError  from './components/VxInputError.vue'
import SMNDatepicker  from './components/SMNDatepicker.vue'
import SmnTable  from './components/SMNTable.vue'
import SmnPermission  from './components/SMNPermission.vue'
import vSelect from 'vue-select'
import SMNSearchInput from './components/SMNSearchInput'


export const globalComponents = {
  VxTooltip,
  VxCard,
  VxList,
  VxBreadcrumb,
  VxInputGroup,
  VxInputError,
  SMNDatepicker,
  SmnTable,
  SmnPermission,
  vSelect,
  SMNSearchInput,
}
