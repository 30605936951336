import Http from './http'

function filter (params) {
    return Http.get(`/auth/filter_student`,  {
        params: params
    })
}

function getByCaretaker () {
    return Http.get(`/auth/students/get_by_caretaker`)
}

function exportStudents (params) {
    return Http.get(`/auth/filter_student`, {
        params: params,
        responseType: 'blob'
    })
}

function getStudents (params) {
    return Http.get(`/auth/student?${params}`)
}

function getStudentCaretakers (id) {
    return Http.get(`/auth/student-contracts/getCaretakersByStudent/${id}`)
}

function getStudent (studentId) {
    return Http.get(`/auth/student/${studentId}`)
}

function updateCaretakers (id, params) {
    return Http.put(`/auth/student/updateCaretaker/${id}`, params)
}

function create (params) {
    return Http.post('/auth/user/save', params)
}

function update (params, userId) {
    return Http.post(`/auth/user/edit/${userId}`, params)
}
function getStudentsWithOutParents(params){
    return Http.get(`/auth/students/withOut/parents/list?${params}`);
}

function getStudentDetails(studentId) {
    return Http.get(`/auth/students/details/${studentId}`)
}
export {
    filter,
    getByCaretaker,
    exportStudents,
    getStudents,
    create,
    update,
    getStudent,
    updateCaretakers,
    getStudentsWithOutParents,
    getStudentDetails,
    getStudentCaretakers,
}