import Http from "./http";

function getSubstitutedProfessorsList(relation) {
    return Http.get(`/auth/substitute_professor?${relation}`);
}

function getAvailableProfessors(relation) {
    return Http.get(`/auth/professors_for_replacement?relation=${relation}`);
}

function getAvailableProfessorsForSubstitute(params) {
    return Http.get(`/auth/professors_for_replacement/get_professors/available`, { params: params })
}

function getSubstituteProfessor(id) {
    return Http.get(`/auth/substitute_professor/${id}`);
}

function getAvailableProfessor(id) {
    return Http.get(`/auth/professors_for_replacement/${id}`);
}

function getSubstitutesCount(params) {
    return Http.get('/auth/professors_for_replacement/substitute_professor/count', { params: params });
}

function saveSubstituteProfessor(params) {
    return Http.post(`/auth/substitute_professor`, params);
}

function saveAvailableProfessor(params) {
    return Http.post(`/auth/professors_for_replacement`, params)
}

function updateSubstituteProfessor(id, params) {
    return Http.put(`/auth/substitute_professor/${id}`, params)
}

function updateAvailableProfessor(id, params) {
    return Http.put(`/auth/professors_for_replacement/${id}`, params)
}

function deleteSubstituteProfessor(id) {
    return Http.delete(`/auth/substitute_professor/${id}`)
}

function deletAvailableProfessor(id) {
    return Http.delete(`/auth/professors_for_replacement/${id}`)
}

function importSubstituteProfessors(params) {
    return Http.post(`/auth/professors_for_replacement/import`, params);
}

function getProfessorsWithAccessToSubstitute(params) {
    return Http.get(`/auth/professors-with-access?relation=${params}`);
}

function updateAccessToSubstitute(id, params) {
    return Http.put(`/auth/professors/${id}/access`, params);
}

function checkProfessorAccess(id, params) {
    return Http.get(`/auth/check-professors-access/${id}`, { params });
}

function getProfessorsAbsencesToSubstitute(params) {
    return Http.get('/auth/professors/absences', { params: params });
}

export {
    getSubstitutedProfessorsList,
    importSubstituteProfessors,
    getAvailableProfessors,
    getAvailableProfessorsForSubstitute,
    getAvailableProfessor,
    getSubstitutesCount,
    getSubstituteProfessor,
    saveSubstituteProfessor,
    saveAvailableProfessor,
    updateSubstituteProfessor,
    updateAvailableProfessor,
    deleteSubstituteProfessor,
    deletAvailableProfessor,
    getProfessorsWithAccessToSubstitute,
    updateAccessToSubstitute,
    checkProfessorAccess,
    getProfessorsAbsencesToSubstitute
}