import Http from './http'

function getCountries () {
    return Http.get(`/auth/country`)
}

function saveCountry (params) {
    return Http.post(`/auth/country`, params)
}

function getMunicipalities () {
    return Http.get(`/auth/municipality`)
}

function saveMunicipality (params) {
    return Http.post(`/auth/municipality`, params)
}

export {
    getCountries,
    saveCountry,
    getMunicipalities,
    saveMunicipality,
}