const environment = process.env.NODE_ENV
// eslint-disable-next-line init-declarations
let config

if (environment === 'development') {
    config = {
        // baseUrl: 'https:/testapi.mileniumi3.net',
        baseUrl: 'http://127.0.0.1:8000',
        // baseUrl: 'http://192.168.2.10',
        apiUrl: 'api'
    }
}

if (environment === 'production') {
    config = {
        // baseUrl: 'https://api.mileniumi3.net',
        baseUrl: 'https://testapi.mileniumi3.net',
        // baseUrl: 'http://192.168.2.10:4002',
        apiUrl: 'api'
    }
}
export { config }
