import Http from "./http";

function setNewFinalGrade(params, id) {
	return Http.put(`auth/updatePeriodFinalGrade/${id}`, params)
}

function setNewHalfYearFinalGrade(params, id) {
	return Http.put(`/auth/updateHalfYearFinalGrade/${id}`, params)
}

function setNewAcademicYearFinalGrade(params, id) {
	return Http.put(`auth/updateAcademicYearFinalGrade/${id}`, params)
}

export {
	setNewFinalGrade,
	setNewHalfYearFinalGrade,
	setNewAcademicYearFinalGrade,
}