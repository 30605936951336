import Http from './http'

function get (params) {
    return Http.get(`/auth/announcement?per_page=${params.per_page}&title=${params.title}&page=${params.page}`)
}

function getById (classId) {
    return Http.get(`/auth/announcement/${classId}`)
}

function create (params) {
    return Http.post('/auth/announcement', params)
}

function update (params, announcement_id) {
    return Http.put(`/auth/announcement/${announcement_id}`, params)
}

function _delete (announcement_id) {
    return Http.delete(`/auth/announcement/${announcement_id}`)
}

export {
    get,
    create,
    update,
    getById,
    _delete
}