import axios from 'axios'
import { config } from './config'
import router from '@/router'

let Http = axios.create({
    baseURL: `${config.baseUrl}/${config.apiUrl}`,
    // timeout: 36000000,
})

Http.interceptors.response.use(response => {
    return response
}, err => {
    if (err.response.status === 401 || err.response.status === 403) {
        if (window.location.pathname.split('/').pop() !== 'login' && (window.location.pathname.split('/').pop() !='reset-password' && 
            window.location.pathname.split('/').pop() != 'forgot-password')) {
            router.push({ name: 'page.login' })
        }
        localStorage.setItem('accessToken', '')
    }

    return Promise.reject(err)
})

const token = localStorage.getItem('accessToken')
Http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

const academicYearId = localStorage.getItem('Academic-Year')
Http.defaults.headers.common['Academic-Year'] = academicYearId;

const caretakerChild = JSON.parse(localStorage.getItem('CaretakerChild'));

if (caretakerChild) {
    Http.defaults.headers.common['Student-id'] = caretakerChild.id;
    Http.defaults.headers.common['User-id'] = caretakerChild.user_id;
}


export default Http