import Http from './http'

function logIn (user) {
    const formData = new FormData()

    formData.append('email', user.email)
    formData.append('password', user.password)

    return Http.post('/auth/login', formData)
}

function getUser () {
    return Http.get('/auth/user')
}

function changePassword (params) {
    return Http.post('/auth/user/changePassword', params)
}

function forgotPassword(params){
    return Http.post('/auth/forget-password', params);
}

function getResetPassword(token){
    return Http.get(`/auth/reset-password/${token}`)
}

function resetPassword(params){
    return Http.post('/auth/reset-password', params)
}

function logOutAllUsers(params){
    return Http.post('/auth/logout/all', params)
}

export {
    logIn,
    getUser,
    changePassword,
    forgotPassword,
    getResetPassword,
    resetPassword,
    logOutAllUsers,
}