import Http from './http'

function get (params) {
    return Http.get(`/auth/student?${params}`)
}

function upload (params) {
    return Http.post(`/auth/documents`, params)
}

function view (file_id) {
    return Http.get(`/auth/files/download/${file_id}`, {
        responseType: 'blob'
    })
}

function deleteDocument (documentId) {
    return Http.delete(`/auth/documents/${documentId}`)
}

export {
    get,
    upload,
    view,
    deleteDocument
}