import { createStore } from 'vuex';

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import moduleAuth from './auth/moduleAuth.js'
import users from './users.js'
import infirmaryModuleStore from '../modules/infirmary/store'

const store = createStore({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    smn: users,
    infirmary: infirmaryModuleStore,
  },
  strict: process.env.NODE_ENV !== 'production'
})
export default store;