import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

// Vuesax Component Framework
import Vuesax from 'vuesax3';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax3/dist/vuesax.css';

app.use(Vuesax)

import moment from 'moment'
import 'moment/locale/sq'

moment.locale('sq')

window.moment = moment

// import VueSweetalert2 from 'vue-swal'

// app.use(VueSweetalert2)

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import { globalComponents } from './globalComponents.js';

for (const [name, component] of Object.entries(globalComponents)) {
  app.component(name, component);
}

import AppIcon from './components/AppIcon.vue'

app.component(AppIcon.name, AppIcon)

import FeatherIcon from './components/FeatherIcon.vue'

app.component(FeatherIcon.name, FeatherIcon)

import '@fortawesome/fontawesome-free/css/all.min.css'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// SMN Admin Filters
import filters from './filters/filters'

app.use(filters)

//Sweetalert
import swal from 'sweetalert';

app.config.globalProperties.$swal = swal

// Clipboard
import VueClipboard from 'vue-clipboard2'
app.use(VueClipboard)

// ApexCharts
import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts);

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

import packageJson from '../package.json'

console.log(`v: ${packageJson.version}`)

// Vue select css
// Note: In latest version you have to add it separately
import 'vue-select/dist/vue-select.css';

app.config.productionTip = false;

app.use(router)

app.use(store)

app.use(i18n)

app.mount('#app')
