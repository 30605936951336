<template>
  <i :class="className"></i>
</template>

<script>

export default {
  functional: true,
  name: 'app-icon',
  props: {
    className: { type: String, required: true, default: ''}
  }
}
</script>