import Http from './http'

const ALL_CLASSES = [
    {
        label: '1',
        class_number: 1,
    },
    {
        label: '2',
        class_number: 2,
    },
    {
        label: '3',
        class_number: 3,
    },
    {
        label: '4',
        class_number: 4,
    },
    {
        label: '5',
        class_number: 5,
    },
    {
        label: '6',
        class_number: 6
    },
    {
        label: '7',
        class_number: 7
    },
    {
        label: '8',
        class_number: 8
    },
    {
        label: '9',
        class_number: 9
    },
    {
        label: '10',
        class_number: 10
    },
    {
        label: '11',
        class_number: 11
    },
    {
        label: '12',
        class_number: 12
    },
    {
        label: 'Grup',
        class_number: 13
    },
    {
        label: 'Grup Studimi',
        class_number: 14 
    }
]

function get(params) {
    return Http.get(`/auth/classes?${params}`)
}

function getById(classId) {
    return Http.get(`/auth/classes/${classId}`)
}

function create(params) {
    return Http.post('/auth/classes', params)
}

function update(params, subjectId) {
    return Http.put(`/auth/classes/${subjectId}`, params)
}

function updateMainProfessor(params, classId) {
    return Http.put(`/auth/classes/mainProfessor/${classId}`, params)
}

function getClassData(classId) {
    return Http.get(`/auth/classes/professor_subject/${classId}`)
}

function getSubjectByCategory(classId, params) {
    return Http.get(`/auth/classes/get_subject_by_category/${classId}`, { params })
}

function makeClassSubjectRelation(params) {
    return Http.post(`auth/classes/professor_subject_create`, params)
}

function deleteClassSubjectRelation(classId, subjectId, professorId) {
    return Http.delete(`auth/classes/professor_subject_delete/${classId}/${subjectId}/${professorId}`)
}

function makeClassStudentRelation(params) {
    return Http.post(`auth/classes/class_student_create`, params)
}

function deleteClassStudentRelation(classId, studentId) {
    return Http.delete(`auth/classes/destroy_student_class/${classId}/${studentId}`)
}

function getLastYearClasses(class_id) {
    return Http.get(`/auth/classes/last_year_classes/${class_id}`)
}

function getClassStudents(class_id) {
    return Http.get(`/auth/classes/class_student/${class_id}`)
}

function importStudents(params) {
    return Http.post(`/auth/classes/class_student_create`, params)
}

function _delete(class_id) {
    return Http.delete(`/auth/classes/${class_id}`)
}

function changeSubjectProffesorOfAClass(classId, subjectId, professorId) {
    return Http.put(`/auth/classes/professor_subject_changed/${classId}/${subjectId}/${professorId}`)
}

function studentTransfer(studentId, oldClass_id, newClass_id) {
    return Http.put(`/auth/classes/student_transfer/${studentId}/${oldClass_id}/${newClass_id}`)
}

function getClassesByUser() {
    return Http.get(`/auth/getClasses/by/User`)
}

function getClassSuperviser() {
    return Http.get(`/auth/superviser/of/class`)
}

function getStudentsToAdd(class_id) {
    return Http.get(`/auth/classes/studentsToAdd/${class_id}`)
}

export {
    get,
    create,
    update,
    updateMainProfessor,
    getById,
    getClassData,
    getSubjectByCategory,
    makeClassSubjectRelation,
    deleteClassSubjectRelation,
    makeClassStudentRelation,
    deleteClassStudentRelation,
    getLastYearClasses,
    getClassStudents,
    importStudents,
    ALL_CLASSES,
    _delete,
    changeSubjectProffesorOfAClass,
    studentTransfer,
    getClassesByUser,
    getClassSuperviser,
    getStudentsToAdd
}