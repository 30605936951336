import Http from './http'

function generate(params) {
    return Http.post('/auth/reports/generate', params, {
        responseType: 'blob'
    })
}

function getDashboardData(params) {
    return Http.get(`/auth/reports/getDashboardData`, params)
}

function getClasses() {
    return Http.get(`/auth/reports/getclasses`)
}

function getHalfYears() {
    return Http.get(`/auth/reports/gethalfyears`)
}

function getSemesters() {
    return Http.get('/auth/semester')
}

function getStudentsByClass(params) {
    return Http.get(`/auth/reports/getStudentsByClass/${params.report_type}/${params.class_id}`)
}

function overallReport() {
    return Http.get(`/auth/reports/overall`)
}

function exportOverallReport(params) {
    return Http.get(`/auth/reports/overall`, { params: params, responseType: 'blob' })
}

export {
    generate,
    getSemesters,
    getClasses,
    getHalfYears,
    getDashboardData,
    getStudentsByClass,
    overallReport,
    exportOverallReport,
}