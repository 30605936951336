import Http from './http'

function get(params) {
    return Http.get(`/auth/student-comments?${params}`);
}

function getById(id) {
    return Http.get(`/auth/student-comments/${id}`);
}

function create(params) {
    return Http.post(`/auth/student-comments`, params);
}

function update(id, params) {
    return Http.put(`/auth/student-comments/${id}`, params);
}

function _delete(id) {
    return Http.delete(`/auth/student-comments/${id}`);
}

export {
    get,
    getById,
    create,
    update,
    _delete,
}