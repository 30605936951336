import Http from './http'

function get (params) {
    return Http.get(`/auth/academicyear?${params}`)
}

function getById (academicYearId) {
    return Http.get(`/auth/academicyear/${academicYearId}`)
}

function getActiveAcademicYear () {
    return Http.get(`/auth/current/academicyear`)
}

function create (params) {
    return Http.post('/auth/academicyear', params)
}

function update (academicYearId, params) {
    return Http.put(`/auth/academicyear/${academicYearId}`, params)
}

function getSemesterById (semesterId) {
    return Http.get(`/auth/semester/${semesterId}`)
}

function addSemester (params) {
    return Http.post(`auth/semester`, params)
}

function updateSemester (params, semesterId) {
    return Http.put(`/auth/semester/${semesterId}`, params)
}

function closeSemester (period_id) {
    return Http.post(`auth/semester/close/${period_id}`)
}

function close (academic_year_id) {
    return Http.post(`/auth/academicyear/close/${academic_year_id}`)
}

export {
    get,
    create,
    update,
    getById,
    getActiveAcademicYear,
    close,
    getSemesterById,
    addSemester,
    updateSemester,
    closeSemester,
}