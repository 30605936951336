import Http from './http'

function getAnnoucements (params) {
    return Http.get(`/auth/announcement?${params}`)
}

function getUnits (params) {
    return Http.get(`/auth/dashboard/units`, {
        params: params
    })
}

function getAbsences (params) {
    return Http.get(`/auth/absences/filter`,  {
        params: params
    })
}

function getRemarks (params) {
    return Http.get(`/auth/filter_remarks`,  {
        params: params
    })
    
}

function getPercentGrade (params) {
    return Http.get(`/auth/dashboard/percent/grade`, { params: params })
}

function getGradesByClass(class_id){
    return Http.get(`/auth/dashboard/percent/gradebyclass/${class_id}`)
}

function getActivePeriodName()
{
    return Http.get(`/auth/dashboard/get_active_period_name`)
}

export {
    getAnnoucements,
    getUnits,
    getAbsences,
    getRemarks,
    getPercentGrade,
    getGradesByClass,
    getActivePeriodName,
}