<template>
	<div id="app" :class="vueAppClasses" v-if="ready">
		<router-view />
	</div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import { authService, studentService } from '@/services'

export default {
	data() {
		return {
			vueAppClasses: [],
			ready: false,
		};
	},
	watch: {
		"$store.state.theme"(val) {
			this.toggleClassInBody(val);
		}
	},
	methods: {
		toggleClassInBody(className) {
			if (className === "dark") {
				if (document.body.className.match("theme-semi-dark"))
					document.body.classList.remove("theme-semi-dark");
				document.body.classList.add("theme-dark");
			} else if (className === "semi-dark") {
				if (document.body.className.match("theme-dark"))
					document.body.classList.remove("theme-dark");
				document.body.classList.add("theme-semi-dark");
			} else {
				if (document.body.className.match("theme-dark"))
					document.body.classList.remove("theme-dark");
				if (document.body.className.match("theme-semi-dark"))
					document.body.classList.remove("theme-semi-dark");
			}
		},
		setAppClasses(classesStr) {
			this.vueAppClasses.push(classesStr);
		},
		handleWindowResize() {
			this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

			// Set --vh property
			document.documentElement.style.setProperty(
				"--vh",
				`${window.innerHeight * 0.01}px`
			);
		},
		handleScroll() {
			this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
		},
		getUser() {
			authService.getUser().then((response) => {
				const user = response.data

				this.$store.dispatch('auth/login', user)
				let academicYear = localStorage.getItem('Academic-Year')
				let academicYearTimestamp = localStorage.getItem('Academic-Year-timestamp')

				this.$store.dispatch('auth/setAcademicYear', (academicYear) ? academicYear : (user.academic_years.length) ? user.academic_years[0].id : null)

				if (academicYearTimestamp) {
					const currentTime = Date.now();
					const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
					if (currentTime - academicYearTimestamp >= twoHoursInMilliseconds) {
						localStorage.setItem('Academic-Year', (user.academic_years.length) ? user.academic_years[0].id : null)
					}
				}

				if (user.type === '5') {
					studentService.getByCaretaker().then(response => {
						const children = response.data.data
						this.$store.dispatch('auth/setCaretakerChildren', children)

						const selectedChild = JSON.parse(localStorage.getItem('CaretakerChild'))
						this.$store.dispatch('auth/setCaretakerChild', (selectedChild) ? selectedChild : null)

						this.ready = true
					}, err => {
						alert('error')
					})
				} else {
					this.ready = true
				}

				if (!user.password_initialized) {
					this.$router.push({ name: 'page.reset-password' })
				}

			}, () => {
				this.ready = true
			})
		}
	},
	mounted() {
		this.toggleClassInBody(themeConfig.theme);
		this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

		const vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty("--vh", `${vh}px`);

		this.getUser();
	},
	async created() {
		window.addEventListener("resize", this.handleWindowResize);
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("resize", this.handleWindowResize);
		window.removeEventListener("scroll", this.handleScroll);
	},
};
</script>
